<template>
    <div class="page-box">
        <div class="page-title flex-center between-box">
            <span>当前位置：项目管理 > <b>垫付申请</b></span>
            <div class="flex-center" @click="backPage">
                <i class="iconfont iconjiantou-copy-copy"></i>返回
            </div>
        </div>
        <div class="sub-sponsor-info">
            <span>垫付申请</span>
            <div class="sub-sponsor-container">
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议名称',pid.cid)}}</div>
                    <el-select
                        ref="pName"
                            v-model="pName"
                            filterable
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            @change="classifyChange($event, 0)">
                        <el-option v-for="(item, index) in projectOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="index">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">所属学会</div>
                    <el-select
                            v-model="society"
                            :placeholder="`请选择所属学会（请选择${$getTitleByType('会议名称',pid.cid)}）`"
                            @change="classifyChange($event, 1)"
                            @visible-change="classifyClick($event, 1)">
                        <el-option
                                v-for="item in societyOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('会议日期',pid.cid)}}</div>
                    <el-input
                            v-model="pid.time"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-title">{{$getTitleByType('大会主席',pid.cid)}}</div>
                    <el-input
                            v-model="pid.chairman"
                            :placeholder="`请选择${$getTitleByType('会议名称',pid.cid)}`"
                            disabled
                    ></el-input>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">金额</div>
                    <el-input v-model="money" placeholder="请输入金额"></el-input>
                    <div class="money">万元</div>
                </div>
                <div class="input-item">
                    <div class="input-item-desc">内容</div>
                    <el-input
                            type="textarea"
                            resize="none"
                            :rows="5"
                            v-model="remark"
                            placeholder="请输入内容"
                    ></el-input>
                </div>
                <approvalList
                        :typeClassify="8"
                        :isNoHave="!id"
                        :society="society"
                        :copyMembers="copyMembers"
                        @setMember="setMember"
                        @setApproval="setApproval"
                ></approvalList>

                <el-button type="primary" @click="subApproval" :loading="requestState" :disabled="requestState" >提交</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import ElInput from "../../../node_modules/element-ui/packages/input/src/input.vue";
    import ElButton from "../../../node_modules/element-ui/packages/button/src/button.vue";
    import approvalList from "../../components/approvalList.vue";

    export default {
        components: {
            ElButton,
            ElInput,
            approvalList,
        },
        name: "advance",
        data() {
            return {
                id: "",
                sign_code: "",
                society: "",
                societyOptions: [],
                pid: "",
                pName: "",
                projectOptions: [],
                money: "",
                remark: "",
                shenpi: "",
                copy: "",
                approvalMembers: [],
                copyMembers: [],
                requestState :false
            };
        },
        created() {
            this.$emit("setIndex", [4, 18]);
            this.id = this.$route.query.id ? this.$route.query.id : '';
            this.init();
        },
        methods: {
            subApproval() {
                if (!this.pid) {
                    this.$message.error(this.$refs.pName.placeholder);
                    return;
                }
                if (!this.society) {
                    this.$message.error("请选择所属学会");
                    return;
                }

                if (!this.money) {
                    this.$message.error("请输入金额");
                    return;
                }
                if (!this.remark) {
                    this.$message.error("请输入内容");
                    return;
                }
                if (this.shenpi.indexOf("-1") !== -1) {
                    this.$message.error("请完善财务人员");
                    return;
                }
                let arr = this.shenpi.split(",");
                if (arr[0] === arr[1]) {
                    this.$message.error("审批人与财务人员不能相同");
                    return;
                }
                this.requestState = true
                //添加
                this.$api.apiContent
                    .approvalAdvance({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sid: this.society,
                        pid: this.pid.id,
                        sign_code: this.sign_code,
                        content: this.remark,
                        money: this.money,
                        shenpi: this.shenpi,
                        copy: this.copy,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                sid: this.society,
                                pid: this.pid.id,
                                sign_code: this.sign_code,
                                money: this.money,
                                content: this.remark,
                                shenpi: this.shenpi,
                                copy: this.copy,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.requestState = false
                        this.$message.success(res.msg);
                        this.$router.push({
                            name: "advanceDetail",
                            query: {id: res.data.id, type: 1},
                        });
                    })
                    .catch((e) => {
                        this.requestState = false
                        this.$message.error(e.msg);
                    });
            },
            classifyChange(index, view) {
                if (view === 0) {
                    let value = this.projectOptions[index];
                    value.time =
                        this.$tools.ZHDate(new Date(value.start_time * 1000)) +
                        " 至 " +
                        this.$tools.ZHDate(new Date(value.end_time * 1000));
                    this.pName = value.name;
                    this.pid = value;
                    this.society = "";
                    this.getSociety();
                }
            },
            classifyClick(event, view) {
                if (event) {
                    if (!this.pid && view === 1) {
                        this.$message.error("请选择会议名称");
                    }
                }
            },
            getSociety() {
                this.$api.apiContent
                    .getSocietyFromPro({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        pid: this.pid.id,
                        sid: this.society,
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                                pid: this.pid.id,
                                sid: this.society,
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        this.societyOptions = res.data;
                        if (res.data.length === 1 && !this.society) this.society = res.data[0].id;
                    })
                    .catch(() => {
                        this.societyOptions = [];
                    });
            },
            //  页面加载时请求
            init() {
                this.$api.apiContent
                    .getProject({
                        token: this.$store.state.user.token,
                        timestamp: this.$tools.requestTime(),
                        sign: this.$tools.SHA256(
                            {
                                token: this.$store.state.user.token,
                                timestamp: this.$tools.requestTime(),
                            },
                            this.$store.state.user.key
                        ),
                    })
                    .then((res) => {
                        res.data.map(item=>{
                            item.name = this.$tools.ZHDate(new Date(item.start_time * 1000)) + " 至 "
                                + this.$tools.ZHDate(new Date(item.end_time * 1000)) +'/'+ item.chairman +'/'+ item. name
                        })
                        this.projectOptions = res.data;
                    })
                    .catch(() => {
                        this.projectOptions = [];
                    });
                if (this.id) {
                    this.$api.apiContent
                        .getAdvance({
                            token: this.$store.state.user.token,
                            timestamp: this.$tools.requestTime(),
                            id: this.id,
                            sign: this.$tools.SHA256(
                                {
                                    token: this.$store.state.user.token,
                                    timestamp: this.$tools.requestTime(),
                                    id: this.id,
                                },
                                this.$store.state.user.key
                            ),
                        })
                        .then((res) => {
//                            this.copyMembers = res.data.copy;
//                            this.copy = res.data.copy
//                                .map((item) => {
//                                    return item.user_id;
//                                })
//                                .join(",");
                            this.sign_code = res.data.sign_code;
                            this.society = res.data.sid;
                            res.data.project.time =
                                this.$tools.ZHDate(
                                    new Date(res.data.project.start_time * 1000),
                                    1
                                ) +
                                " 至 " +
                                this.$tools.ZHDate(new Date(res.data.project.end_time * 1000), 1);
                            res.data.project.id = res.data.pid;
                            this.pid = res.data.project;
                            this.pName = this.$tools.ZHDate(new Date(res.data.project.start_time * 1000), 1) + " 至 "
                                + this.$tools.ZHDate(new Date(res.data.project.end_time * 1000), 1) + '/' + res.data.project.chairman + '/' +  res.data.project.name;
                            this.money = res.data.money;
                            this.remark = res.data.content;
                            this.getSociety();
                        })
                        .catch(() => {
                        });
                }
            },
            backPage() {
                this.$tools.goBack();
            },
            //审批人
            setApproval(members) {
                this.shenpi = members;
            },
            //抄送人
            setMember(members) {
                this.copy = members;
            },
        }
    };
</script>

<style scoped lang="scss">
    .page-box {
        .page-title {
            height: 40px;
            margin-bottom: 10px;

            span {
                font-size: 18px;
                line-height: 36px;
                color: #999999;

                b {
                    color: #333;
                    font-weight: normal;
                }
            }

            div {
                color: #3b77e7;
                font-size: 20px;
                cursor: pointer;

                i {
                    display: block;
                    width: 24px;
                    height: 24px;
                    text-align: center;
                    line-height: 24px;
                    margin-right: 5px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 14px;
                    background-color: #3b77e7;
                }
            }
        }
        .sub-sponsor-info {
            width: 100%;
            background-color: #ffffff;
            border-radius: 6px;
            padding: 40px 25px;
            min-height: 800px;
            span {
                color: #000000;
                font-size: 24px;
            }
            .sub-sponsor-container {
                width: 60%;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                .input-item {
                    .upfile-list {
                        width: 400px;
                        margin-bottom: 12px;
                        li {
                            height: 31px;
                            border-bottom: 1px dashed #ccc;

                            a {
                                display: block;
                                width: 350px;
                                height: 30px;
                                line-height: 30px;
                                color: #3b77e7;
                            }

                            .el-icon-delete {
                                font-size: 16px;
                                cursor: pointer;

                                &:hover {
                                    color: #3b77e7;
                                }
                            }
                        }
                    }
                    .money {
                        flex-shrink: 0;
                        margin-left: 10px;
                    }
                    display: flex;
                    margin-bottom: 20px;
                    flex-direction: row;
                    align-items: center;
                    overflow: hidden;
                    .input-item-title {
                        width: 20%;
                        text-align: center;
                        font-size: 16px;
                        margin-right: 20px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .input-item-desc {
                        width: 20%;
                        align-self: flex-start;
                        margin-top: 10px;
                        margin-right: 20px;
                        text-align: center;
                        font-size: 16px;
                        color: #000000;
                        flex-shrink: 0;
                    }
                    .el-input,
                    .el-autocomplete,
                    .el-select,
                    .el-textarea,
                    .el-radio-group,
                    .el-date-editor,
                    .el-upload {
                        flex-grow: 1;
                        resize: none;
                    }
                }
                > .el-button {
                    margin-top: 30px;
                    align-self: center;
                    width: 40%;
                }
            }
        }
    }
</style>
